<template>
  <div class="row p-lg-8">
    <!-- {{stats}} -->
    <div class="col-12 col-sm-12 mt-2">
      <div class="row mb-3">
        <div class="col-12 text-right stat-filter-bar">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn" :class="filter_stat === 1 ? 'btn--blue' : 'btn--babyblue'" @click="changeFilterstat(1)">Hôm nay</button>
            <button type="button" class="btn" :class="filter_stat === 2 ? 'btn--blue' : 'btn--babyblue'" @click="changeFilterstat(2)">Tuần</button>
            <button type="button" class="btn" :class="filter_stat === 3 ? 'btn--blue' : 'btn--babyblue'" @click="changeFilterstat(3)">Tháng</button>
            <button type="button" class="btn" :class="filter_stat === 4 ? 'btn--blue' : 'btn--babyblue'" @click="changeFilterstat(4)">Quý</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 stat-box">
          <div class="card radius-18 h-100">
            <div class="card-header">
              Bác sĩ
            </div>
            <div class="card-body d-flex align-items-center w-100 h-100" style="padding: 5px 14px;">
              <div class="stat-box-icon" style="margin-right: 5px;">
                <img src="../../public/assets/images/icon/doctor_home/patient.svg" alt="">
              </div>
              <div class="d-flex flex-column w-75" v-if="stats">
                <div class="d-flex justify-content-between robo-16-500"><div>Active</div><div class="txt-pri">{{stats.doctor_total_ago}}</div></div>
                <div class="d-flex justify-content-between robo-16-500"><div>Total</div><div class="txt-pri">{{stats.doctor_total}}</div></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 stat-box">
          <div class="card radius-18 h-100">
            <div class="card-header">
              Bệnh nhân
            </div>
            <div class="card-body d-flex align-items-center w-100 h-100" style="padding: 5px 14px;">
              <div class="stat-box-icon" style="margin-right: 5px;">
                <img src="../../public/assets/images/icon/doctor_home/patient.svg" alt="">
              </div>
              <div class="d-flex flex-column w-75" v-if="stats">
                <div class="d-flex justify-content-between robo-16-500"><div>Active</div><div class="txt-pri">{{stats.patient_total_ago}}</div></div>
                <div class="d-flex justify-content-between robo-16-500"><div>Total</div><div class="txt-pri">{{stats.patient_total}}</div></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 stat-box">
          <div class="card radius-18 h-100">
            <div class="card-header">
              Yêu cầu
            </div>
            <div class="card-body d-flex align-items-center w-100 h-100" style="padding: 5px 14px;">
              <div class="stat-box-icon" style="margin-right: 5px;">
                <img src="../../public/assets/images/icon/doctor_home/patient.svg" alt="">
              </div>
              <div class="d-flex flex-column w-75" v-if="stats">
                <div class="d-flex justify-content-between robo-16-500"><div>In-progres</div><div class="txt-pri">{{stats.request_total_progress}}</div></div>
                <div class="d-flex justify-content-between robo-16-500"><div>Pending</div><div class="txt-pri">{{stats.request_total_new}}</div></div>
                <div class="d-flex justify-content-between robo-16-500"><div>Total</div><div class="txt-pri">{{stats.request_total}}</div></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 stat-box">
          <div class="card radius-18 h-100">
            <div class="card-header">
              Lịch hẹn
            </div>
            <div class="card-body d-flex align-items-center w-100 h-100" style="padding: 5px 14px;">
              <div class="stat-box-icon" style="margin-right: 5px; ">
                <img src="../../public/assets/images/icon/doctor_home/patient.svg" alt="">
              </div>
              <div class="d-flex flex-column w-75" v-if="stats">
                <div class="d-flex justify-content-between robo-16-500"><div>Online</div><div class="txt-pri">{{stats.appt_total_online}}</div></div>
                <div class="d-flex justify-content-between robo-16-500"><div>Offline</div><div class="txt-pri">{{stats.appt_total_offline}}</div></div>
                <div class="d-flex justify-content-between robo-16-500"><div>Total</div><div class="txt-pri">{{stats.total_appointment}}</div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <PersonList :persons="requests" @sortPerson="sortPerson"></PersonList>
        <Pagination v-show="requests && requests.count" :items="requests" :current_page="current_page" @onRefresh="getRequest"></Pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import PersonList from './Cms/PersonList'
  import Pagination from './Pagination'
  export default {
    name: 'AdminDashboard',
    components: {PersonList, Pagination},
    data () {
      return {
        stats: {
          file_uploads: 0,
          total_appointment: 0,
          total_clinic: 0,
          total_person: 0
        },
        params: {
          limit: 10,
          page: 1,
          start_time: '',
          end_time: ''
        },
        filter_stat: 1,
        requests: null,
        current_page: 0,
        sort_create: 2,
        sort_last: 0
      }
    },
    async mounted () {
      let self = this
      self.getStat()
      self.getRequest()
    },
    watch: {
      '$route.query.type': {
        handler: function () {
          let self = this
          self.page = self.$route.query.page
          self.getRequest()
        },
        deep: true
      }
    },
    methods: {
      changeFilterstat (type) {
        let self = this
        self.filter_stat = type
        self.getStat()
      },
      async getStat () {
        let self = this
        var params = {
          start_time: '',
          end_time: ''
        }
        if (self.filter_stat === 1) {
          params.start_time = window.moment(new Date()).startOf('day').format('HH:ss DD-MM-YYYY')
          params.end_time = window.moment(new Date()).endOf('day').format('HH:ss DD-MM-YYYY')
        } else if (self.filter_stat === 2) {
          params.start_time = window.moment(new Date()).startOf('week').format('HH:ss DD-MM-YYYY')
          params.end_time = window.moment(new Date()).endOf('week').format('HH:ss DD-MM-YYYY')
        } else if (self.filter_stat === 3) {
          params.start_time = window.moment(new Date()).startOf('month').format('HH:ss DD-MM-YYYY')
          params.end_time = window.moment(new Date()).endOf('month').format('HH:ss DD-MM-YYYY')
        } else if (self.filter_stat === 4) {
          params.start_time = window.moment(new Date()).startOf('quarter').format('HH:ss DD-MM-YYYY')
          params.end_time = window.moment(new Date()).endOf('quarter').format('HH:ss DD-MM-YYYY')
        }
        let resp = await self.$rf.getRequest('AdminRequest').getStats(params)
        self.stats = resp.data
      },
      async getRequest () {
        let self = this
        try {
          self.current_page = parseInt(self.$route.query.page) || 1
          self.params.page = self.current_page
          self.params.start_time = window.moment(new Date()).subtract(3, 'days').hours(0).minutes(0).seconds(0).milliseconds(0).format('HH:mm DD-MM-YYYY')
          self.params.end_time = window.moment(new Date()).endOf('day').format('HH:mm DD-MM-YYYY')
          if (self.sort_create === 1) {
            self.params.sort = 'created_at'
            self.params.sort_by = 'asc'
          } else if (self.sort_create === 2) {
            self.params.sort = 'created_at'
            self.params.sort_by = 'desc'
          }
          if (self.sort_last === 1) {
            self.params.sort = 'last_login'
            self.params.sort_by = 'asc'
          } else if (self.sort_last === 2) {
            self.params.sort = 'last_login'
            self.params.sort_by = 'desc'
          }
          let resp = await self.$rf.getRequest('AdminRequest').getUser(self.params)
          self.requests = resp.data
        } catch (e) {
          // statements
          console.log(e)
        }
      },
      sortPerson (sort_create, sort_last) {
        let self = this
        self.sort_create = sort_create
        self.sort_last = sort_last
        self.getRequest()
      }
    }
  }
</script>
